.work {
  /* background: radial-gradient(#656565 0%, #4F4F4F 20%, #3B3B3B 40%, #1E1E1E); */
  text-align: center;
  padding: 100px 0 50px 0;
  background: url(../../Assets/Images/Frame\ 657.png);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: #000;
  background-size: contain;
}

/* .content-box {
    margin-bottom: 100px;
} */

.work h2 {
  font-size: 70px;
  margin-bottom: 30px;
  color: #fff;
  font-weight: 400;
  letter-spacing: 2.3px;
}

.work p.work-details {
  font-size: 20px;
  color: #fff;
  width: 35%;
  margin: 0 auto;
  letter-spacing: 1.5px;
}

@media (max-width: 900px) {
  .work h2 {
    font-size: 60px;
  }

  .work p.work-details {
    width: 54%;
  }
}

@media (max-width: 640px) {
  .work h2 {
    font-size: 48px;
  }

  .work p.work-details {
    width: 70%;
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .work h2 {
    font-size: 36px;
  }

  .work p.work-details {
    width: 80%;
    font-size: 14px;
  }

  .project-btn {
    max-width: 160px;
  }
}
