header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffffab;
  border-radius: 60px;
  padding: 20px 40px;
  position: fixed;
  width: 60%;
  left: 50%;
  top: 10px;
  transition: 0.2s ease-in-out;
  transform: translate(-50%, 50%);
  z-index: 99;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  animation: headerUp 1.3s ease-in-out forwards;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

header.open {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

header ul {
  display: flex;
}

header ul li {
  cursor: pointer;
  margin-right: 30px;
  font-size: 16px;
  transition: all 0.3s ease-out;
}

header ul li::after {
  content: "";
  width: 100%;
  display: block;
  height: 2px;
  background-color: #333;
  transform: translateY(10px);
  opacity: 0;
  border-radius: 20px;
  transition: 0.3s ease-in-out;
}

header ul li.active::after {
  opacity: 1;
  /* transform: translateY(6px); */
}

.menu {
  display: none;
  width: 36px;
}

.open .menu span:nth-child(1) {
  transform: rotate(45deg) translateX(14px);
}

.open .menu span:nth-child(3) {
  transform: rotate(-45deg) translateX(14px);
}

.open .menu span:nth-child(2) {
  transform: scaleX(0);
}

.menu span {
  width: 100%;
  display: block;
  background-color: #000;
  height: 4px;
  transition: 0.3s ease-in-out;
  border-radius: 35px;
}

.menu span:nth-child(2) {
  margin: 6px 0;
}

.menu svg {
  font-size: 30px;
}

header .mobile-menu {
  display: none;
}

@media (max-width: 920px) {
  header ul li {
    margin-right: 15px;
  }
}

@media (max-width: 800px) {
  header ul {
    display: none;
  }

  .menu {
    display: block;
    position: absolute;
    right: 30px;
  }

  header .mobile-menu {
    display: block;
  }
  header ul li {
    margin: auto;
  }
}

@media (max-width: 480px) {
  header {
    width: 40vh;
    padding: 14px 30px;
  }

  header ul li.active::after {
    opacity: 1;
    /* transform: translateY(6px); */
  }

  header img {
    width: 60px;
  }

  .menu {
    width: 28px;
  }
}

@keyframes headerUp {
  0% {
    transform: translate(-50%, 50%) translateY(-100px);
  }

  100% {
    transform: translate(-50%, 50%) translateY(0);
  }
}
