.service {
    background: url(../../Assets/Images/Frame\ 657.png);
    padding: 100px 40px;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-color: #000;
    background-size: contain;
}

.service h3 {
    font-size: 60px;
    color: #E8EAEC;
    text-align: center;
    font-weight: 400;
    margin-bottom: 50px;
    letter-spacing: 2.3px;
}

.service .items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
}


.service .items .item {
    width: 26%;
    padding: 30px;
    border-radius: 20px;
    transition: .5s ease-in;
    position: relative;
    display: flex;
    align-items: flex-start;
    overflow: hidden;
    cursor: pointer;
}

.service .items .item::before {
    content: "";
    width: 100%;
    display: block;
    height: 600px;
    top: -60px;
    left: -10px;
    background: linear-gradient(3600deg, rgba(239, 13, 13, 0) 0%, rgba(147, 147, 147, 0.5) 50%, rgba(255, 255, 255, 1) 100%);
    position: absolute;
    opacity: 0;
    transform: rotate(0);
    z-index: -1;
    transition: 3s ease-in-out;
    animation: rotateBorder 3s ease-in-out infinite;
}

.service .items .item::after {
    content: "";
    top: 50%;
    left: 0;
    background-color: #1E1E1E;
    position: absolute;
    inset: 2px;
    border-radius: 20px;
}


.service .items .item:hover {
    transition: .5s ease-in;
    transform: scale(1.03);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

}


.service .items .item:hover::before {
    opacity: 1;
    transform: rotate(360deg);

}

/* .service .items .item:hover h4,
.service .items .item:hover p {
    color: #1E1E1E;
}

.service .items .item:hover img {
    filter: invert(1);
} */

.service .items .item h4 {
    color: #E8EAEC;
    margin-bottom: 14px;
    position: relative;
    z-index: 4;
    letter-spacing: 1.5px;
    font-size: 24px;
}

.service .items .item p {
    color: #E8EAEC;
    font-size: 12px;
    text-align: left;
    position: relative;
    z-index: 4;
    letter-spacing: 1px;
    font-size: 18px;
}

.service .items .item:nth-child(3n) {
    margin-right: 0;
}

.service .items .item .containet-top {
    text-align: left;
}

.service .items .item img {
    margin-right: 10px;
    position: relative;
    z-index: 4;
    /* margin-left: -35px; */
}



@media (max-width: 1280px) {
    .service .items .item {
        width: 40%;
        padding: 30px;
    }
}

@media (max-width: 1000px) {
    .service h3 {
        font-size: 50px;
    }

    .service .items .item h4 {
        font-size: 18px;
    }

    .service .items .item p {
        font-size: 12px;
    }
}

@media (max-width: 880px) {
    .service .items .item {
        width: 70%;
        padding: 50px;
    }

    .service .items .item h4 {
        font-size: 20px;
    }

    .service .items .item p {
        font-size: 14px;
    }
}

@media (max-width: 640px) {
    .service {
        padding: 100px 20px;
    }

    .service h3 {
        font-size: 40px;
    }

    .service .items .item h4 {
        font-size: 18px;
    }

    .service .items .item p {
        font-size: 12px;
    }

    .service .items .item {
        width: 80%;
    }
}

@media (max-width: 480px) {
    .service h3 {
        font-size: 36px;
    }

    .service .items .item {
        padding: 30px;
    }
}