.project-list {
  padding: 100px 80px;
  background: url(../../Assets/Images/Frame\ 657.png);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: #000;
  background-size: contain;
}

.project-list h2 {
  color: #fff;
  font-size: 70px;
  text-align: center;
  margin-bottom: 60px;
}

.project-cards {
  display: flex;
  justify-content: center;
  gap: 50px;
  flex-wrap: wrap;
}

.item-card {
  position: relative;
  width: 350px;
  overflow: hidden;
  background-color: #161616;
  border-radius: 20px;
  text-align: center;
  padding: 50px 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  transition: 0.5s ease-in;
  z-index: 44;
}

/* .item-card:nth-child(n + 4) {
  margin-top: 50px;
} */

.item-card h6 {
  color: #fff;
  font-size: 24px;
  margin-bottom: 12px;
  margin-top: 8px;
  letter-spacing: 1.5px;
}

.item-card p {
  color: #fff;
  font-size: 18px;
  width: 80%;
  margin: 0 auto;
  line-height: 1.3em;
  letter-spacing: 1.5px;
}

.item-card .image-box {
  width: 124px;
  margin: 40px auto;
  background: #fff;
  border-radius: 50%;
  height: 124px;
  background-size: cover;
}

.item-card .image-box img {
  width: 100%;
  display: block;
  border-radius: 50%;
}

.item-card::before {
  content: "";
  width: 100%;
  display: block;
  height: 600px;
  top: -60px;
  left: -10px;
  background: linear-gradient(
    90deg,
    rgba(30, 30, 30, 0) 0%,
    rgba(147, 147, 147, 0.5) 50%,
    rgba(255, 255, 255, 1) 100%
  );
  position: absolute;
  opacity: 0;
  z-index: -1;
  transition: 2s ease-in-out;
  animation: rotateBorder 3s ease-in-out infinite;
  /* Animation added */
}

@keyframes rotateBorder {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.item-card::after {
  content: "";
  top: 50%;
  left: 0;
  background-color: #1e1e1e;
  position: absolute;
  inset: 2px;
  border-radius: 20px;
  z-index: -1;
}

.item-card:hover::before {
  opacity: 1;
  background: linear-gradient(
    180deg,
    rgba(6, 239, 29, 0.343) 0%,
    rgba(6, 216, 220, 0.64) 50%,
    rgba(211, 5, 5, 0.573) 100%
  );
}

.item-card:hover::after {
  inset: 4px;
}

/* .button-container{
    display: flex;
    padding: 35px;
} */

/* Default State: Black background */
/* .project-bttn {
    position: relative;
    margin: auto;
    width: 100%;
    padding: 16px 40px;
    max-width: 200px;
    background: black;
    color: white;
    transform: translate3d(0px, 0%, 0px);
    text-decoration: none;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: .05em;
    transition-delay: .6s;
    border-radius: 122px;
    overflow: hidden;
    border: white;
    cursor: pointer;
} */

/* White effect animation */
/* .project-bttn:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    border-radius: 50% 50% 0 0;
    transform: translateY(100%) scaleY(.5);
    transition: all .6s ease;
} */

/* .project-bttn:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    border-radius: 0;
    transform: translateY(0) scaleY(1);
    transition: all .6s ease;
    z-index: -1;
} */

/* Inner text positioning */
/* .project-bttn div {
    position: relative;
    top: 16px;
    width: 100%;
    height: 26px;
} */

/* Text transition */
/* .project-bttn span {
    position: absolute;
    z-index: 1;
    top: -10px;
    left: 0;
    width: 100%;
    text-align: center;
    transition: transform .5s ease;
} */
/* 
.project-bttn span:first-child {
    color: black;
    transform: translateY(24px);
} */
/* 
.project-bttn span:last-child {
    color: white;
    transform: translateY(0);
} */

/* Hover: Turns White */
/* .project-bttn:hover {
    background: white;
    color: black;
    transition: background .2s linear;
    transition-delay: .6s;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 8px 0px;
} */

/* Hover Effect */
/* .project-bttn:hover:after {
    border-radius: 0 0 50% 50%;
    transform: translateY(-100%) scaleY(.5);
    transition-delay: 0;
} */

/* .project-bttn:hover:before {
    border-radius: 0;
    transform: translateY(0) scaleY(1);
    transition-delay: 0;
} */

/* Text Animation */
/* .project-bttn:hover span:first-child {
    transform: translateY(0);
} */

/* .project-bttn:hover span:last-child {
    transform: translateY(-40px);
} */

/* Active (Clicked) State: Stays White */
/* .project-bttn.active {
    background: white;
    color: black;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 8px 0px;
} */

/* Ensuring hover doesn't override active */
/* .project-bttn.active:hover {
    background: white;
    color: black;
} */

.button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 18px;
}
.project-bttn {
  font-size: 16px;
  padding: 19px 25px;
  margin-top: 10px;
  margin-bottom: 50px;
  background-color: black;
  color: white;
  font-weight: 600;
  border-radius: 122px;
  overflow: hidden;
  border: 1px solid white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-out;
}
.project-bttn:hover {
  background-color: white;
  color: black;
}
.project-bttn.active {
  background-color: white;
  color: black;
}

@media (max-width: 1340px) {
  .item-card {
    width: 330px;
  }
}

@media (max-width: 1280px) {
  .item-card {
    width: 310px;
    padding: 20px;
  }
}

@media (max-width: 1155px) {
  .project-cards {
    justify-content: center;
    gap: 50px;
  }

  .item-card {
    width: 360px;
    padding: 50px 20px;
  }

  .item-card:nth-child(n + 3) {
    margin-top: 0;
  }
}

@media (max-width: 1024px) {
  .button-container {
    margin-bottom: 60px;
  }
  .item-card {
    width: 340px;
    padding: 40px 16px;
  }

  .project-list h2 {
    font-size: 60px;
  }
  .button-container {
    flex-wrap: wrap;
    gap: 20px;
  }
  .project-bttn {
    margin-bottom: 10px;
  }
}

@media (max-width: 960px) {
  .item-card {
    width: 310px;
    padding: 30px 10px;
  }

  .project-list {
    padding: 100px 44px;
  }
}

@media (max-width: 800px) {
  .item-card {
    width: 350px;
    padding: 40px 20px;
  }
  .project-bttn {
    font-size: 14px;
    padding: 16px 22px;
    margin-top: 8px;
    margin-bottom: 5px;
    border-radius: 100px;
  }
}

@media (max-width: 600px) {
  .project-list h2 {
    font-size: 50px;
  }
}

@media (max-width: 480px) {
  .project-list h2 {
    font-size: 36px;
  }

  .item-card {
    padding: 20px 10px;
  }

  .project-list {
    padding: 100px 20px;
  }
  .button-container {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    gap: 15px;
  }
  .project-bttn {
    margin: 0;
    font-size: 13px;
    padding: 11px 14px;
  }
}
