.spotlight {
    height: 100vh;
    background-color: #000;
    display: flex;
    align-items: center;
    text-align: center;
    position: relative;
}

/* .spotlight::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background-color: #fff;
    border-top-left-radius: 100%;
    border-top-right-radius: 100%;
    z-index: 1;
} */


.spotlight .image-box img.top-bub {
    position: absolute;
    top: -200px;
    left: 100px;
    width: 200px;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    transition: 2s ease-in-out;
    -webkit-animation-name: rotateIn;
    animation-name: rotateIn;
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-animation-duration: 10s;
    animation-duration: 10s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.spotlight .image-box img.bottom-bub {
    position: absolute;
    top: 150px;
    left: 1100px;
    width: 200px;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    transition: 2s ease-in-out;
    -webkit-animation-name: rotateOut;
    animation-name: rotateOut;
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-animation-duration: 10s;
    animation-duration: 10s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;

}

.spotlight .heading {
    width: 100%;
}

.spotlight h2 {
    font-size: 58px;
    font-weight: normal;
    color: #fff;
    display: block;
}

.spotlight button {
    margin-top: 20px;
    /* border: none;
    outline: none;
    font-size: 16px;
    display: flex;
    align-items: center;
    margin: 20px auto 0;
    background-color: #fff;
    border-bottom: 2px solid #004AD8;
    color: #004AD8;
    padding-bottom: 4px;
    padding: 10px; */
    border: none;
    outline: none;
    font-size: 16px;
    display: flex;
    align-items: center;
    margin: 20px auto 0;
    border-bottom: 2px solid #5CA0B9;
    color: #5CA0B9;
    padding: 10px;
    background-color: transparent;
    background-image: linear-gradient(to bottom right, transparent 50%, #5CA0B9 50%);
    background-size: 200% 200%;
    background-position: left top;
    transition: background-position 0.4s ease-in;
}

.spotlight button:hover {
    background-position: right bottom;
    color: #fff;
    border-bottom: 2px solid #000;
}

.spotlight button svg {
    width: 24px;
    height: 24px;
}

.spotlight button:hover svg {
    transform: translatex(10px);
    transition: .4s ease-in;
}

@media (max-width: 1280px) {
    .spotlight .image-box img.bottom-bub {
        left: 850px;
    }
}

@media (max-width: 1100px) {
    .spotlight .image-box img.top-bub {
        top: -100px;
        left: 0;
    }

    .spotlight .image-box img.bottom-bub {
        left: 650px;
        top: 100px;
    }
}

@media (max-width: 900px) {
    .spotlight .image-box img.top-bub {
        width: 150px;
    }

    .spotlight .image-box img.bottom-bub {
        left: 600px;
        width: 150px;
    }

    .spotlight h2 {
        font-size: 48px;
    }
}

@media (max-width: 800px) {
    .spotlight .image-box img.bottom-bub {
        left: 450px;
        width: 150px;
    }
}

@media (max-width: 640px) {
    .spotlight .image-box img.bottom-bub {
        display: none;
    }

    .spotlight .image-box img.top-bub {
        top: 30px;
        left: 200px;
    }

    .spotlight h2 {
        font-size: 40px;
    }

    .spotlight {
        height: 80vh;
    }
}

@media (max-width: 480px) {
    .spotlight .image-box img.top-bub {
        top: 0px;
        left: 120px;
    }

    .spotlight h2 {
        font-size: 36px;
    }
}





@keyframes rotateIn {
    from {
        -webkit-transform: rotate3d(0, 0, 1, -360deg);
        transform: rotate3d(0, 0, 1, -360deg);
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 1);
        opacity: 1;
    }
}

@keyframes rotateOut {
    from {
        opacity: 1;
    }

    to {
        -webkit-transform: rotate3d(0, 0, 1, 360deg);
        transform: rotate3d(0, 0, 1, 360deg);
    }
}