.projects {
  padding: 100px 70px 50px 70px;
  background: url(../../Assets/Images/Frame\ 657.png);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: #000;
  background-size: contain;
}

.project-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: space-around;
}

/* .card-item-upper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 120px;
} */

.card-item {
  position: relative;
  z-index: 44;
  width: 350px;
  overflow: hidden;
  background-color: #161616;
  border-radius: 23px;
  text-align: center;
  padding: 30px 0;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  transition: 0.5s ease-in;
  margin-bottom: 30px;
}

.card-item::before {
  content: "";
  width: 100%;
  display: block;
  height: 600px;
  top: -60px;
  left: -10px;
  background: linear-gradient(
    90deg,
    rgba(30, 30, 30, 0) 0%,
    rgba(147, 147, 147, 0.5) 50%,
    rgba(255, 255, 255, 1) 100%
  );
  position: absolute;
  opacity: 0;
  z-index: -1;
  transition: 2s ease-in-out;
  animation: rotateBorder 3s ease-in-out infinite;
  /* Animation added */
}

@keyframes rotateBorder {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.card-item::after {
  content: "";
  top: 50%;
  left: 0;
  background-color: #1e1e1e;
  position: absolute;
  inset: 2px;
  border-radius: 20px;
  z-index: -1;
}

.card-item:hover::before {
  opacity: 1;
  background: linear-gradient(
    180deg,
    rgba(6, 239, 29, 0.343) 0%,
    rgba(6, 216, 220, 0.64) 50%,
    rgba(211, 5, 5, 0.573) 100%
  );
}

.card-item:hover::after {
  inset: 4px;
}

.card-item:hover {
  transform: translateY(-5px) scale(1.03);
}

/* .card-item-upper .card-item.top,
.card-item-bottom .card-item.top {
  transform: translateY(-100px);
}

.card-item-upper .card-item.top:hover,
.card-item-bottom .card-item.top:hover {
  transform: translateY(-100px) scale(1.03);
}

.card-item-upper .card-item:hover,
.card-item-bottom .card-item:hover {
  transform: scale(1.03);
  box-shadow: 0px 0px 19.9px 4px rgba(245, 247, 248, 0.423);
  cursor: pointer;
}

.card-item-upper .card-item.middle,
.card-item-bottom .card-item.last {
  transform: translateY(250px);
} */

.card-item h6 {
  color: #fff;
  font-size: 24px;
  margin-bottom: 12px;
  margin-top: 8px;
  letter-spacing: 1.5px;
}

.card-item p {
  color: #fff;
  font-size: 18px;
  width: 80%;
  margin: 0 auto;
  line-height: 1.3em;
  letter-spacing: 1.5px;
}

.card-item .image-box {
  width: 124px;
  margin: 40px auto;
  background: #fff;
  border-radius: 50%;
  height: 124px;
  background-size: cover;
}

.card-item .image-box img {
  width: 100%;
  display: block;
  border-radius: 50%;
}

/* .card-item-middle {
    display: flex;
    justify-content: center;
} */

/* .card-item-middle .card-item {
    position: relative;
    z-index: 44;
    width: 350px;
    overflow: hidden;
    background-color: #161616;
    border-radius: 20px;
    text-align: center;
    padding: 50px 20px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    transition: .5s ease-in;
    transform: translateY(-400px);
} */

/* .card-item-bottom {
  display: flex;
  justify-content: space-between;
  margin-bottom: 300px;
} */

/* .card-item-bottom .card-item {
  position: relative;
  z-index: 44;
  width: 350px;
  overflow: hidden;
  background-color: #161616;
  border-radius: 20px;
  text-align: center;
  padding: 30px 0;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  transition: 0.5s ease-in;
} */

/* .card-item-last {
    display: flex;
    justify-content: center;
} */

/* .card-item-last .card-item {
    position: relative;
    z-index: 44;
    width: 350px;
    overflow: hidden;
    background-color: #161616;
    border-radius: 20px;
    text-align: center;
    padding: 50px 20px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    transition: .5s ease-in;
    transform: translateY(-400px);
} */

.content-box {
  text-align: center;
  margin-top: 100px;
}

.content-box p.work-paragraph {
  font-size: 18px;
  color: #fff;
  width: 50%;
  margin: 0 auto 40px;
  line-height: 1.3em;
  letter-spacing: 1.5px;
}

.project-btn {
  position: relative;
  margin: auto;
  width: 100%;
  padding: 16px 20px;
  max-width: 200px;
  background: #fff;
  transform: translate3d(0px, 0%, 0px);
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.05em;
  transition-delay: 0.6s;
  border-radius: 122px;
  overflow: hidden;
  border: none;
  cursor: pointer;
}

.project-btn:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  border-radius: 50% 50% 0 0;
  transform: translateY(100%) scaleY(0.5);
  transition: all 0.6s ease;
}

.project-btn:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 0;
  transform: translateY(0) scaleY(1);
  transition: all 0.6s ease;
  z-index: -1;
}

.project-btn div {
  position: relative;
  top: 16px;
  width: 100%;
  height: 26px;
}

.project-btn span {
  position: absolute;
  z-index: 1;
  top: -10px;
  left: 0;
  width: 100%;
  text-align: center;
  transition: transform 0.5s ease;
}

.project-btn span:first-child {
  color: white;
  transform: translateY(24px);
}

.project-btn span:last-child {
  color: #1e0f21;
  transform: translateY(0);
}

.project-btn:hover {
  background: #000;
  transition: background 0.2s linear;
  transition-delay: 0.6s;
  color: #fff;
  box-shadow: rgba(242, 240, 240, 0.338) 0px 2px 8px 0px;
}

.project-btn:hover:after {
  border-radius: 0 0 50% 50%;
  transform: translateY(-100%) scaleY(0.5);
  transition-delay: 0;
}

.project-btn:hover:before {
  border-radius: 0;
  transform: translateY(0) scaleY(1);
  transition-delay: 0;
}

.project-btn:hover span:first-child {
  transform: translateY(0);
}

.project-btn:hover span:last-child {
  transform: translateY(-40px);
}

/* @media (max-width: 1280px) {
  .card-item-upper {
    flex-wrap: wrap;
    width: 90%;
    margin: 0 auto;
  }

  .card-item-bottom {
    width: 90%;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    margin: 0 auto 100px;
  }

  .card-item-upper .card-item,
  .card-item-bottom .card-item {
    width: 400px;
  }

  .card-item-upper .card-item.top,
  .card-item-bottom .card-item.top {
    transform: translateY(-60px);
    margin-top: 300px;
  }

  .card-item-upper .card-item:first-child,
  .card-item-bottom .card-item:first-child {
    margin-bottom: 50px;
  }
} */

/* @media (max-width: 1150px) {
  .card-item-upper {
    width: 100%;
  }

  .card-item-bottom {
    width: 100%;
  }
} */

/* @media (max-width: 1000px) {
  .card-item-upper .card-item,
  .card-item-bottom .card-item {
    width: 360px;
  }
} */

@media (max-width: 900px) {
  .card-item-upper .card-item,
  .card-item-bottom .card-item {
    width: 400px;
  }

  .card-item-bottom {
    flex-direction: unset;
  }

  .card-item-upper,
  .card-item-bottom {
    justify-content: center;
  }

  .card-item-upper .card-item.top,
  .card-item-bottom .card-item.top {
    transform: translateY(-60px);
    margin-top: 150px;
  }

  .card-item-upper .card-item.middle,
  .card-item-bottom .card-item.last {
    transform: translateY(20px);
  }

  .content-box p.work-paragraph {
    width: 80%;
  }
}

@media (max-width: 640px) {
  .content-box p.work-paragraph {
    width: 90%;
  }
}

@media (max-width: 480px) {
  .card-item p {
    font-size: 16px;
  }

  .content-box p.work-paragraph {
    font-size: 14px;
  }

  .project-btn {
    max-width: 160px;
  }

  .projects {
    padding: 100px 30px;
  }

  .content-box {
    margin-top: 50px;
  }
}
