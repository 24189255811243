.privacy-main {
    background-color: #e8e5e5;
}

.privacy-content {
    width: 70%;
    margin: 0 auto;
    padding: 100px 0;
}

.privacy-content h5 {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 20px;
    letter-spacing: 1px;
}

.privacy-content p {
    margin-bottom: 18px;
    font-size: 18px;
    letter-spacing: 1px;
}

.privacy-content h6 {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 10px;
}

.btn {
    background-color: #000;
    color: #fff;
    padding: 14px 20px;
    border-radius: 16px;
    width: 30%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    display: block;
    margin-top: 50px;
}

a.lnk {
    display: block;
}

@media (max-width:640px) {
    .privacy-content {
        width: 90%;
    }
}