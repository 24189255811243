* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
}


@font-face {
    font-family: 'ClashDisplay-Regular';
    src: url('../../Assets/fonts/ClashDisplay-Regular.otf') format('opentype');
}

body {
    font-family: 'ClashDisplay-Regular';
}