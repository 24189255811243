.about {
  padding: 100px 0 100px 100px;
  /* background: radial-gradient(#656565 0%, #4F4F4F 20%, #3B3B3B 40%, #1E1E1E); */
  height: 500px;
  background: url(../../Assets/Images/Frame\ 657.png);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: #000;
  background-size: contain;
}

.about h2 {
  font-size: 80px;
  font-weight: 700;
  margin-bottom: 60px;
  color: #fff;
  text-align: left;
  letter-spacing: 2.3px;
}

.about .about-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: 0 auto;
}

.about .about-left ul {
  width: 42%;
  text-align: left;
}

.about .about-left ul li {
  font-size: 20px;
  margin-bottom: 30px;
  color: #fff;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  width: fit-content;
  padding-bottom: 6px;
  letter-spacing: 1.5px;
}

/* 
.about .about-left ul li::after {
    content: "";
    width: 100%;
    display: block;
    height: 2px;
    background-color: #333;
    transform: translateY(10px);
    opacity: 0;
    border-radius: 20px;
    transition: .3s ease-in-out;
}

.about .about-left ul li.active::after {
    opacity: 1;
    transform: translateY(6px);
} */
.about .about-left ul li.active {
  border-bottom: 2px solid #fff;
}

.about .about-left ul li svg {
  font-size: 26px;
  margin-left: 6px;
}

.about .about-left .content {
  width: 70%;
  display: none;
}

/* 
.about .about-left .content p {
    color: #fff;
    font-size: 20px;
    text-align: left;
} */

.show-content {
  display: block;
  width: 80%;
  text-align: left;
}

.show-content p {
  font-size: 18px;
  color: #fff;
  line-height: 1.7em;
  letter-spacing: 1.5px;
}

@media (max-width: 1100px) {
  .about h2 {
    font-size: 60px;
    text-align: center;
  }

  .about {
    padding: 100px 50px;
  }

  .about .about-left {
    width: 100%;
    flex-direction: column;
  }

  .show-content {
    width: 90%;
    text-align: center;
    margin: 0 auto;
  }

  .about .about-left ul {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .about .about-left ul li {
    margin-right: 26px;
  }

  .about .about-left ul li svg {
    display: none;
  }

  .about .about-left .content {
    width: 90%;
    margin: 0 auto;
  }

  .about .about-left .content p {
    text-align: center;
  }
}

@media (max-width: 800px) {
  .about .about-left .show-content p {
    font-size: 16px;
  }
}

@media (max-width: 640px) {
  .about h2 {
    font-size: 50px;
  }

  .about .about-left ul li {
    font-size: 18px;
  }

  .about .about-left .show-content p {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .about {
    padding: 100px 20px;
    height: 550px;
  }

  .about h2 {
    font-size: 40px;
  }

  .about .about-left ul li {
    font-size: 14px;
  }
}
