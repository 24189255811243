.design {
  /* height: 55vh; */
  height: 480px;
  position: relative;
  background-color: #fff;
  overflow: hidden;
  padding: 100px 60px;
}

.design ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.design ul li {
  width: 80px;
  opacity: 0.3;
}

.design ul li.react {
  position: absolute;
  top: -320px;
  left: 200px;
  -webkit-animation: slide-in-elliptic-left-fwd 5s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite alternate both;
  animation: slide-in-elliptic-left-fwd 5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    infinite alternate both;
}

.design ul li.bootstrap {
  position: absolute;
  top: -180px;
  left: 60px;
  -webkit-animation: slide-in-elliptic-right-fwd 5s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite alternate both;
  animation: slide-in-elliptic-right-fwd 5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    infinite alternate both;
}

.design ul li.figma {
  position: absolute;
  top: -100px;
  right: 170px;
  -webkit-animation: slide-in-elliptic-right-fwd 5s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite alternate both;
  animation: slide-in-elliptic-right-fwd 5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    infinite alternate both;
}

.design ul li.js {
  -webkit-animation: slide-in-elliptic-right-fwd 5s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite alternate both;
  animation: slide-in-elliptic-right-fwd 5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    infinite alternate both;
}

.design ul li.Mongo {
  -webkit-animation: slide-in-elliptic-right-fwd 5s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite alternate both;
  animation: slide-in-elliptic-right-fwd 5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    infinite alternate both;
}

.design ul li.css {
  position: absolute;
  left: 130px;
  top: 100px;
  -webkit-animation: slide-in-elliptic-left-fwd 5s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite alternate both;
  animation: slide-in-elliptic-left-fwd 5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    infinite alternate both;
}

.design ul li.python {
  width: 100px;
  -webkit-animation: slide-in-elliptic-left-fwd 5s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite alternate both;
  animation: slide-in-elliptic-left-fwd 5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    infinite alternate both;
}

.design ul li.firebase {
  width: 150px;
  position: absolute;
  right: 0;
  top: 150px;
  -webkit-animation: slide-in-elliptic-left-fwd 5s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite alternate both;
  animation: slide-in-elliptic-left-fwd 5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    infinite alternate both;
}

.design ul li.ai {
  -webkit-animation: slide-in-elliptic-left-fwd 5s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite alternate both;
  animation: slide-in-elliptic-left-fwd 5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    infinite alternate both;
}

.design ul li.html {
  -webkit-animation: slide-in-elliptic-left-fwd 5s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite alternate both;
  animation: slide-in-elliptic-left-fwd 5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    infinite alternate both;
}

.design ul li.flutter {
  width: 100px;
  position: absolute;
  top: 100px;
  left: 600px;
  -webkit-animation: slide-in-elliptic-left-fwd 5s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite alternate both;
  animation: slide-in-elliptic-left-fwd 5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    infinite alternate both;
}

.design ul li.dart {
  width: 100px;
  position: absolute;
  right: 150px;
  top: -270px;
  -webkit-animation: slide-in-elliptic-left-fwd 5s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite alternate both;
  animation: slide-in-elliptic-left-fwd 5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    infinite alternate both;
}

/* 
@keyframes slide-in-elliptic-left-fwd {
  0% {
    -webkit-transform: translateX(-800px) rotateY(30deg) scale(0);
    transform: translateX(-800px) rotateY(30deg) scale(0);
    -webkit-transform-origin: -100% 50%;
    transform-origin: -100% 50%;
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0) rotateY(0) scale(1);
    transform: translateX(0) rotateY(0) scale(1);
    -webkit-transform-origin: 1800px 50%;
    transform-origin: 1800px 50%;
    opacity: 1;
  }
}

@keyframes slide-in-elliptic-right-fwd {
  0% {
    -webkit-transform: translateX(800px) rotateY(-30deg) scale(0);
    transform: translateX(800px) rotateY(-30deg) scale(0);
    -webkit-transform-origin: -100% 50%;
    transform-origin: -100% 50%;
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0) rotateY(0) scale(1);
    transform: translateX(0) rotateY(0) scale(1);
    -webkit-transform-origin: -1800px 50%;
    transform-origin: -1800px 50%;
    opacity: 1;
  }
} */

.design ul li img {
  display: block;
  width: 100%;
}

/* .design {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
} */

/* .design video {
    width: 100%;
    height: 100%;
    object-fit: contain;
} */

/* .design .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.301);
} */

.design .content-box {
  width: 64%;
  margin: 0 auto 60px;
}

.design .content-box h2 {
  font-size: 50px;
  margin-bottom: 12px;
  color: #000;
  letter-spacing: 2.3px;
}

.design .content-box h4 {
  font-size: 22px;
  margin-bottom: 12px;
  letter-spacing: 1.5px;
}

.design .content-box p {
  font-size: 18px;
  color: #000;
  letter-spacing: 1.3px;
}

/* @media (max-width: 1280px) {
  .design {
    height: 50vh;
  }
} */

@media (max-width: 1050px) {
  .design .content-box {
    width: 80%;
  }

  .design ul {
    display: none;
  }
  .design {
    /* height: 40vh; */
    height: 100%;
  }
}

@media (max-width: 900px) {
  .design .content-box {
    margin-bottom: 20px;
  }

  .design ul li.firebase {
    top: 106px;
  }

  .design .content-box {
    width: 100%;
  }

  .design .content-box h2 {
    font-size: 40px;
  }

  .design .content-box h4 {
    font-size: 20px;
  }
  .design {
    padding: 100px 20px;
    /* height: 30vh; */
  }
}

@media (max-width: 700px) {
  .design ul li.react {
    left: 100px;
  }

  .design ul li.dart {
    right: 100px;
  }

  .design .content-box h2 {
    font-size: 34px;
  }

  .design .content-box h4 {
    font-size: 16px;
  }

  .design .content-box p {
    font-size: 14px;
  }

  .design ul li.react img,
  .design ul li.bootstrap img,
  .design ul li.figma img,
  .design ul li.dart img {
    opacity: 0.6;
  }
}

@media (max-width: 480px) {
  .design .content-box h2 {
    font-size: 30px;
  }

  .design .content-box h4 {
    font-size: 14px;
  }

  .design {
    padding: 100px 20px;
    /* height: 30vh; */
  }

  .design ul li {
    width: 50px;
  }
}
