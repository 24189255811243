.career {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  background-image: url("../../Assets/Images/career.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: brightness();
}
.career_container {
  width: 100%;
  /* height: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  /* padding-left: 200px; */
}

.career_container h1 {
  font-size: 60px;
  font-weight: 300;
  margin-bottom: 20px;
  color: rgb(197, 189, 189);
}

.career_container h5 {
  font-size: 26px;
  font-weight: 300;
  margin-bottom: 15px;
  color: rgb(199, 194, 194);
}
.career_container h3 {
  font-size: 50px !important;
  margin-bottom: 15px;
  font-weight: 800;
}
.career_container p {
  font-size: 18px;
  margin-bottom: 10px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  width: 40%;
  font-weight: 400;
  text-align: center;
}
.career_container h6 {
  font-size: 20px;
  color: rgb(180, 177, 177);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;
  margin-bottom: 10px;
}

.aTag {
  text-decoration: underline;
  color: #ffffff !important;
  cursor: pointer;
  padding: 1px 10px;
}
.aTag:hover {
  color: #366fb4 !important;
}

.cv_form {
  /* width: 100%;
    height: 80px; */
  width: 50%;
  background-color: rgba(245, 242, 239, 0.151);
  padding: 30px;
  border-radius: 8px;
}

.cv_form input {
  background-color: #151419;
  width: 80%;
}

@media (max-width: 1010px) {
  .career_container p {
    width: 70%;
  }
}

@media (max-width: 540px) {
  .career_container {
    padding: 50px;
  }
}

@media (max-width: 480px) {
  .career_container h1 {
    font-size: 46px;
  }
  .career_container h5 {
    font-size: 20px;
  }
  .career_container p {
    font-size: 14px;
  }
  .career_container h6 {
    font-size: 14px;
  }
}

@media (max-width: 400px) {
  .career_container h1 {
    font-size: 40px;
  }
  .career_container h5 {
    font-size: 18px;
  }
  .career_container p {
    font-size: 14px;
    width: 100%;
  }
  .career_container h6 {
    font-size: 14px;
  }
  .career_container {
    padding: 25px;
  }
}
