footer {
  padding: 150px 100px 50px 100px;
}

footer .logo-box {
  width: 200px;
}

footer .logo-box img {
  width: 100%;
  display: block;
}

footer .footer-detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

footer .footer-detail .footer-left {
  width: 30%;
  border-right: 2px solid #000;
}

footer .footer-detail .footer-left h4 {
  font-size: 36px;
  letter-spacing: 1px;
}

footer .footer-detail .footer-left h6 a {
  font-size: 20px;
  display: flex;
  align-items: center;
  margin-top: 20px;
  letter-spacing: 1px;
  color: #000;
}

.footer-right p a {
  text-decoration: none;
  color: #000;
}

footer .footer-detail .footer-left h6 svg,
footer .footer-detail .footer-left p svg,
footer .footer-detail .footer-right p svg {
  margin-right: 8px;
  width: 30px;
}

footer .footer-detail .footer-left p a {
  margin-top: 16px;
  font-size: 22px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  color: #000;
}

footer .footer-detail .footer-right {
  width: 30%;
}

footer .footer-detail .footer-right p {
  width: 80%;
  font-size: 18px;
  letter-spacing: 1px;
  margin-bottom: 16px;
}

footer .social {
  display: flex;
  gap: 30px;
}

footer .social li {
  width: 30px;
  height: 30px;
  background-color: #000;
  padding: 6px;
  border-radius: 50%;
  cursor: pointer;
}

footer .social li img {
  width: 28px;
  height: 28px;
}

footer .social li:hover {
  transform: scale(1.1);
  transition: 0.4s ease-in;
}

.termsprivacy {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  gap: 20px;
}

/* .termsprivacy .terms {
  border: 0.5px solid #000;
    padding: 10px 14px;
    border-radius: 6px;
} */

/* .termsprivacy .privacy {
  border: 0.5px solid #000;
} */

@media (max-width: 1200px) {
  footer .footer-detail {
    flex-direction: column;
  }

  footer .footer-detail .footer-left {
    border-right: none;
    padding-right: 0;
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
  }

  footer .footer-detail .footer-left h6.phone,
  footer .footer-detail .footer-left p.mail {
    justify-content: center;
    display: flex;
  }

  footer .footer-detail .footer-right {
    padding-left: 0;
    width: 60%;
    text-align: center;
  }

  footer .footer-detail .footer-right p {
    width: 100%;
  }

  footer .logo-box {
    margin-bottom: 50px;
  }

  footer .social {
    justify-content: center;
  }
}

@media (max-width: 640px) {
  footer .footer-detail .footer-right {
    width: 100%;
  }
}

@media (max-width: 480px) {
  footer .logo-box {
    width: 150px;
  }

  footer .footer-detail .footer-left h4 {
    font-size: 22px;
  }

  footer {
    padding: 100px 50px;
  }

  footer .footer-detail .footer-left h6,
  footer .footer-detail .footer-left p,
  footer .footer-detail .footer-right p {
    font-size: 16px;
  }

  footer .social li img {
    width: 25px;
    height: 29px;
  }
}

@media (max-width: 400px) {
  .termsprivacy {
    flex-direction: column;
    margin-top: 50px;
    gap: 10px;
  }
}
