.mobile-menu ul.navbar {
  background-color: #b9b9b9;
  width: 77%;
  padding: 80px;
  position: absolute;
  top: 100%;
  right: 50%;
  /* opacity: 0; */
  transform: translateX(50%) translateY(-100px);
  display: block;
  border-bottom-left-radius: 60px;
  border-bottom-right-radius: 60px;
  transition: 0.2s ease-in-out;
  z-index: -1;
  text-align: center;
  display: none;
}

.mobile-menu ul.navbar.open {
  display: block;
  /* opacity: 1; */
  transform: translateX(50%) translateY(0);
}

.mobile-menu ul.navbar li {
  font-size: 16px;
  margin-bottom: 40px;
}

.mobile-menu ul.navbar .close {
  font-size: 30px;
  margin-bottom: 50px;
  position: absolute;
  top: 30px;
}

.mobile-menu ul.navbar li:hover {
  border-bottom: 2px solid #000;
}

@media (max-width: 800px) {
  .mobile-menu ul.navbar {
    width: 68%;
  }
}

@media (max-width: 640px) {
  .mobile-menu ul.navbar {
    width: 60%;
  }
}

@media (max-width: 480px) {
  .mobile-menu ul.navbar {
    width: 38%;
  }
}
